// Google Map
$map-min-height: calc( 100vh - 70px );
$map-max-height: 400px;

.google-map-markers{
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map {
	width: 100%;
	height: $map-min-height;
	max-height: $map-max-height;
}
