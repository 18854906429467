.embed-custom-21by9 {
	padding-top: percentage(9 / 21);
}

.embed-custom-16by9 {
	padding-top: percentage(9 / 16);
}

.embed-custom-4by3 {
	padding-top: percentage(3 / 4);
}

.embed-custom-1by1 {
	padding-top: percentage(1 / 1);
}
