//
// Breadcrumb custom
// --------------------------------------------------

// Breadcrumb Classic
//
.breadcrumb-classic {
  position: relative;

  &{
    @include bg-variant-custom(linear-gradient(90deg, $picton-blue 0%, $dollar-bill 100%), $picton-blue);
  }

  &:before{
    @include bg-variant-custom(linear-gradient(270deg, $picton-blue 0%, $dollar-bill 100%), $picton-blue);
  }

  &, a {
    color: $white;
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: fadeBoth 6s infinite;
  }

  & > *:not(svg) {
    position: relative;
    z-index: 1;
  }

  .svg-triangle-bottom{
    position: absolute;
    top: 100%;
    left: 50%;
    width: 60px;
    height: 30px;
    margin-top: -1px;
    margin-left: -30px;
    z-index: 2;
  }
}

// Breadcrumb Default
//
.breadcrumb-default {
  padding: 28px 15px;
  background: $gray-100;
  > li{
    display: inline-block;
    a{
      color:$gray-700;
      font-size: 15px;
      &:hover{
        color: $picton-blue;
      }
    }
    & + li{
      margin-left: 3px;
      &:before{
        content:"\f234";
        font-family: "Material Design Icons";
        color:$gray-700;
        padding-right: 4px;
      }
    }
  }
}

// Breadcrumb Modern
//
.breadcrumb-modern {
}

// Bootstrap Breadcrumb
//
// TODO: Adapt bootstrap breadcrumb to intense color scheme

.breadcrumb{

}

