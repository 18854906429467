//
// RD Navbar Default
// --------------------------------------------------

.rd-navbar-floated {
  @extend %rd-navbar-default-cart;
  &.rd-navbar-fixed {
    @extend %rd-navbar-default-panel;
    @extend %rd-navbar-default-mobile-menu;
    @extend %rd-navbar-default-cart-aside-right;
    @extend %rd-navbar-default-aside-left;
    @extend %rd-navbar-default-mobile-search;

    .rd-navbar-brand{
      display: none;
    }

    .rd-navbar-search {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      .rd-navbar-mobile-brand{
        display: none;
      }
      .rd-navbar-brand{
        display: block;
        transform: translateX(-50%);
      }
    }
  }

  &.rd-navbar-static {
    margin-top: 30px;
    @extend %rd-navbar-transition;
    .rd-navbar-mobile-brand{
      display: none;
    }

    @extend %rd-navbar-default-menu-first-lvl;
    @extend %rd-navbar-default-menu-dropdown;
    @extend %rd-navbar-default-search;
    @extend %rd-navbar-default-desktop-cart;

    .rd-navbar-dropdown{
      margin-top: 29px;
    }


    .rd-navbar-brand {
      float: left;
    }

    .rd-navbar-menu-wrap {
      float: right;
    }

    .rd-navbar-panel .rd-navbar-toggle, .form-search-wrap {
      display: none;
    }

    .rd-navbar-inner {
      @include clearfix;
      transition: .3s all ease;
      position: relative;
      text-align: left;
      padding: 23px 40px;
      border-radius: $border-radius-small;
      box-shadow: $shadow-area-md;
      &.active {
        .rd-navbar-panel {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .rd-navbar-megamenu{
      left: 0;
      right: 0;
    }

    .rd-navbar-nav-wrap, .rd-navbar-panel, .rd-navbar-cart-wrap {
      transition: .3s all ease;
    }

    .rd-navbar-nav-wrap {
      @include clearfix;
      margin-top: 6px;
      display: inline-block;
    }

    // Cart
    .rd-navbar-cart-wrap {
      display: inline-block;
      position: relative;
      margin-left: 20px;
    }

    .rd-navbar-search-toggle, .rd-navbar-cart {
      margin-top: 5px;
    }

    .rd-navbar-search-form {

      .form-label {
        padding-left: 40px;
        &.focus {
          left: 16px;
          top: 12px;
        }
      }

      .rd-navbar-search-form-input {
        padding-left: 40px;
      }
    }

    &.rd-navbar--is-stuck {
      box-shadow: $shadow-area-md;
      margin-top: 0;
      width: 100%;
      padding: 0;

      .rd-navbar-nav > li > .rd-navbar-dropdown {
        margin-top: 26px;
      }
    }

  }

  // Themes

  // Dark Theme
  // -------------------

  &.rd-navbar-dark {
    .rd-navbar-inner{
    background: $black;
    }
    @extend %rd-navbar-default-cart-dark;


    // Mobile Fixed Layout Colors
    &.rd-navbar-fixed {
      @extend %rd-navbar-default-panel-dark;
      @extend %rd-navbar-default-mobile-menu-dark;
      @extend %rd-navbar-default-cart-aside-right-dark;
      @extend %rd-navbar-default-aside-left-dark;
      @extend %rd-navbar-default-mobile-search-dark;
    }

    // Desktop Static Layout Colors
    &.rd-navbar-static {
      @extend %rd-navbar-default-menu-first-lvl-dark;
      @extend %rd-navbar-default-menu-dropdown-dark;
      @extend %rd-navbar-default-search-dark;

      .rd-navbar-search-form-input, .rd-navbar-search-toggle, .rd-navbar-cart {
        color: $white;
      }

      &.rd-navbar--is-stuck {
        background: $black;
      }

    }
  }

  // Light Theme
  // -------------------

  &.rd-navbar-light {
    .rd-navbar-inner{
      background: $white;
    }

    @extend %rd-navbar-default-cart-light;

    .rd-navbar-search-toggle, .rd-navbar-cart {
      span {
        color: $gray-900;
      }
    }

    // Mobile Fixed Layout Colors
    &.rd-navbar-fixed {
      @extend %rd-navbar-default-panel-light;
      @extend %rd-navbar-default-mobile-menu-light;
      @extend %rd-navbar-default-cart-aside-right-light;
      @extend %rd-navbar-default-aside-left-light;
      @extend %rd-navbar-default-mobile-search-light;
    }

    // Desktop Static Layout Colors
    &.rd-navbar-static {
      @extend %rd-navbar-default-menu-first-lvl-light;
      @extend %rd-navbar-default-menu-dropdown-light;
      @extend %rd-navbar-default-search-light;

      .rd-navbar-search-form-input, .rd-navbar-search-toggle, .rd-navbar-cart {
        color: $gray-900;
      }
      &.rd-navbar--is-stuck {
        background: $white;
      }
    }
  }

}
