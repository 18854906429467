//
// RD Navbar Default Cart
// --------------------------------------------------
%rd-navbar-default-desktop-cart {
  .rd-navbar-cart {
    @include toggle-icons-via-rotation(24px, 22px, $white, '\f206', '\f69a');
  }
  .rd-navbar-cart-dropdown {
    border-top: 1px solid $primary;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    transition: .3s all ease;
    text-align: left;
    width: 240px;
    z-index: 1;
    left: auto;
    right: 0;
    padding: 13px 15px 15px;
    box-shadow: $shadow-area-sm;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

//
// Dark Theme
// --------------------------------------------------
%rd-navbar-default-desktop-cart-dark {
}

//
// Light Theme
// --------------------------------------------------
%rd-navbar-default-desktop-light {
}
