//
// RD Navbar Default Menu Dropdown
// --------------------------------------------------

%rd-navbar-default-menu-dropdown{
  .rd-navbar-dropdown, .rd-navbar-megamenu, .rd-navbar-cart-dropdown {
    border-top: 1px solid $primary;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    transition: .3s all ease;
    padding: 0;
    text-align: left;
    width: 240px;
    box-shadow: $shadow-area-xs;
    z-index: 1;
    .rd-navbar-dropdown{
      left: 100%;
      top: -1px;
      margin: 0;
      z-index: 2;
    }
    .rd-navbar-dropdown .rd-navbar-dropdown{
      z-index: 3;
    }
    &.rd-navbar-open-left, &.rd-navbar-open-left .rd-navbar-dropdown{
      right: 100%;
      left: auto;
    }
  }

  .rd-navbar-nav > .rd-navbar-submenu >.rd-navbar-dropdown.rd-navbar-open-left{
    right: 0;
  }

  .rd-navbar-megamenu {
    padding: 15px;
    max-width: 1900px;
    margin-left: auto;
    width: auto;
    box-shadow: $shadow-area-xs;
    right: 20px;
    left: 20px;
  }

  .rd-navbar-dropdown, .rd-navbar-cart-dropdown {
    margin-top: 49px;
  }

  .rd-navbar-dropdown, .rd-navbar-megamenu {
    li {
      position: relative;
      > a {
        @extend  %rd-navbar-default-link-font;
        display: block;
        padding: 8px 30px 8px 20px;
        > span{
          vertical-align: middle;
        }
      }
      .rd-navbar-label{
        margin-left: 5px;
      }
      .rd-navbar-icon{
        margin-right: 10px;
        font-size: 18px;
        line-height: 24px;
        vertical-align: middle;
      }
    }
    li {
      &.rd-navbar--has-dropdown:after {
        content: '\f238';
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 13px;
        font-size: 22px;
        line-height: 34px;
        font-family: 'Material Design Icons';
        transition: .3s all ease;
        transform: translateY(-50%);
      }
    }
  }

  // Megamenu Tabs
  .responsive-tabs .resp-tabs-list {
    text-align: left;
    padding-left: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: -15px;
      right: -15px;
      top: 100%;
      border-bottom: 1px solid transparent;
    }
    li {
      &:before {
        display: none;
      }
      &.resp-tab-active {
        @extend %text-gradient;

        &, &:before {
          color: #42B574;
          background-image: -webkit-linear-gradient(0deg, $primary 0%, $dollar-bill 100%);
        }
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

//
// Dark Theme
// --------------------------------------------------
%rd-navbar-default-menu-dropdown-dark{

  .rd-navbar-dropdown, .rd-navbar-megamenu {
    background: darken(saturate(adjust_hue($black, 0), 0%), 1.5686274509803937%);
    li {
      &.rd-navbar--has-dropdown:after {
        color: $gray-800;
      }
      > a {
        color: $white;
      }
      .rd-navbar-icon {
        color: $gray-700;
      }
      &:hover {
        > a {
          background: $black;
          color: $white;
        }
        &:after {
          color: $white;
        }
      }
    }

    .responsive-tabs .resp-tabs-list {
      &:before {
        border-color: $gray-900;
      }
      li {
        color: $gray-700;
        &.resp-tab-active {
          &, &:before {
            color: $primary;
          }
          &:hover {
            color: $primary;
          }
        }
        &:hover {
          color: $white;
        }
      }
    }
  }

}

//
// Light Theme
// --------------------------------------------------
%rd-navbar-default-menu-dropdown-light{
  .rd-navbar-dropdown, .rd-navbar-megamenu {
    background: $white;
    li {
      &.rd-navbar--has-dropdown:after {
        color: $gray-800;
      }
      > a {
        color: $gray-800;
      }
      .rd-navbar-icon {
        color: $gray-500;
      }
      &:hover {
        > a {
          background: $black;
          color: $white;
        }
        &:after {
          color: $white;
        }
      }
    }

    .responsive-tabs .resp-tabs-list {
      &:before {
        border-color: $gray-500;
      }
      li {
        color: $gray-700;
        &.resp-tab-active {
          &, &:before {
            color: $primary;
          }
          &:hover {
            color: $primary;
          }
        }
        &:hover {
          color: $gray-900;
        }
      }
    }
  }

}
