//
// RD Audio
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.rd-audio {
  position: relative;

  // audio muted
  &.muted .rd-audio-volume:before {
    content: '\f66e';
  }

  &.state-playing .rd-audio-play-pause:before {
    content: '\f4d3';
  }

}

.rd-audio-icon {
  font-size: 16px;
  color: $rd-audio-player-controls-color;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
}

// Audio Play pause
// --------------------------------------------------
.rd-audio-play-pause {
  position: relative;
  font-size: 20px;
//  vertical-align: middle;
  &.has-controls {
    margin: 0 5px;
    &:after {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid rgba($rd-audio-player-controls-color, 0.5);
      left: 50%;
      top: 50%;
    }
  }
  @include media-breakpoint-up(md) {
    font-size: 24px;
    &.has-controls {
      margin: 0 13px;
      &:after {
        width: 36px;
        height: 36px;
      }
    }
  }
}

// Audio progress bar
// --------------------------------------------------
.rd-audio-progress-bar {
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  height: 8px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 0;
    right: 0;
    background: $gray-200;
  }

  .current {
    position: absolute;
    left: 0;
    top: 3px;
    bottom: 3px;
    @include bg-variant-custom(linear-gradient(90deg, $primary 0%, $dollar-bill 100%));
    z-index: 0;
  }

  @include media-breakpoint-up(sm) {
    position: relative;
    width: 100%;
    top: 0;
  }

}

// Audio Progress bar wrap
// --------------------------------------------------
.rd-audio-progress-bar-wrap {
  width: 60%;
  margin: 0 5px 0 15px;
  @include media-breakpoint-up(sm) {
    margin: 0 17px 0 18px;
    position: relative;
    width: 100%;
  }
}

// Controls
// --------------------------------------------------

.rd-audio-controls {
  transition: .3s all ease;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  will-change: opacity;
  padding: 8px 10px;
  box-shadow: $shadow-area-md;
  background: $white;
  position: relative;

  &-left {
    white-space: nowrap;
    width: 20%;
    &:before{
      content: '';
      display: inline-block;
      height: 100%;
    }
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &-right {
    width: 10%;
  }

  @include media-breakpoint-up(sm) {
    padding: 18px 10px;
  }
  @include media-breakpoint-up(lg) {
    padding: 18px 30px;
  }
}

// Audio Prev
// --------------------------------------------------
.rd-audio-prev {
  transform: rotate(180deg);
}

// Audio Volume
// --------------------------------------------------

.rd-audio-volume {
  margin-right: 5px;
  &-bar {
    position: relative;
    cursor: pointer;
    height: 8px;
    width: 80px;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 0;
      right: 0;
      background: $gray-200;
    }

    .current {
      position: absolute;
      left: 0;
      top: 3px;
      bottom: 3px;
      width: 100%;
      @include bg-variant-custom(linear-gradient(90deg, $primary 0%, $dollar-bill 100%));
      z-index: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-right: 10px;
  }
}

.rd-audio-volume, .rd-audio-volume-bar {
  display: none;
  @include media-breakpoint-up(lg) {
    .desktop & {
      display: block;
    }
  }
}

// Audio Track title
// --------------------------------------------------
.rd-audio-title-wrap {
  position: relative;
  font-weight: 700;
  font-size: 13px;
  max-width: 100%;
  width: 100%;
  margin: 0 10px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  @include media-breakpoint-up(sm) {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: auto;
    margin: 0;
  }
  @include media-breakpoint-up(md) {
    max-width: 70%;
  }
}

.rd-audio-title-divider {
  display: none;
}

.rd-audio-title-wrap {
  .rd-audio-title, .rd-audio-author {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @include media-breakpoint-up(sm) {
    .rd-audio-title, .rd-audio-author, .rd-audio-title-divider {
      display: inline;
    }
  }
}

// Audio Track Time Position
// --------------------------------------------------
.rd-audio-time {
  position: absolute;
  top: 100%;
  right: 0;
  font-style: italic;
  font-size: 13px;
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-up(md) {
    top: auto;
    bottom: 100%;
  }
}

// Audio Playlist Buttons
// --------------------------------------------------
.rd-audio-playlist-button {
  margin-left: 15px;
  @include media-breakpoint-up(sm) {
    margin-left: 20px;
  }
}

// Audio Playlist Styles
// --------------------------------------------------
.rd-audio-playlist-wrap {
  position: absolute;
  left: 0;
  bottom: 100%;
  right: 0;
  background: $white;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  text-align: left;
  display: none;
  margin-bottom: -5px;
  padding-bottom: 5px;
}

.rd-audio-playlist-title {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  border-bottom: 1px solid $gray-500;
  text-align: left;
  padding: 7px 18px;
  margin-bottom: 0;
  font-size: 13px;
}

.rd-audio-playlist {
  list-style-type: none;
  padding-left: 0;
  max-height: 170px;
  overflow-y: auto;
  li {
    cursor: pointer;
    padding: 8px 15px;
    position: relative;
    @include clearfix;
    transition: .3s all ease;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:before {
      content: '\f4fc';
      font-family: 'Material Design Icons';
      opacity: .5;
      font-size: 24px;
      display: inline-block;
      line-height: 24px;
      margin-right: 10px;
      vertical-align: middle;
      font-weight: 400;
    }
    &.playing, &.paused {
      background: $gray-100;
    }
    &.playing:before {
      content: '\f4d5';
      opacity: 1;
    }

    &:hover {
      background: $gray-300;
    }
    @include media-breakpoint-up(sm) {
      padding: 8px 70px 8px 18px;
    }
  }
}

.song-duration {
  position: absolute;
  right: 18px;
  font-style: italic;
  opacity: .5;
  display: none;
  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
}

