/**
 * RD Datepicker
 * @Section
 */
$datepicker-color: #333;
$datepicker-border: 1px solid #ebebeb;
$datepicker-background: #fff;
$datepicker-font-size: 18px;
$datepicker-line-height: 30px;

$datepicker-day-color: #333;
$datepicker-day-background: transparent;

$datepicker-day-hover-color: $primary;
$datepicker-day-hover-background: #038AC6;

$datepicker-today-color: #fff;
$datepicker-today-background: #333;

$datepicker-day-selected-color: #fff;
$datepicker-day-selected-background: #038AC6;

$datepicker-day-offset-color: #ccc;
$datepicker-day-offset-background: transparent;

.rd-mailform-time-picker.form-group {
  position: relative;
}

.form-input {
  display: inline-block;
  width: 100%;
  font-size: $datepicker-font-size;
  line-height: $datepicker-line-height;
  border: 2px solid;
  padding: 3px 20px;
  height: 40px;
}

.rd-datepicker {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 292px;
  color: $datepicker-color;
  background: $datepicker-background;
  border: $datepicker-border;
  transition: .3s all;
  z-index: 99;

  &-header {
    position: relative;
  }

  &-body {
    width: 100%;
    table-layout: fixed;
    padding: 4px;
  }

  &-title {
    padding: 5px 40px;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
  }

  &-day,
  &-week {
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  &-day {
    color: $datepicker-day-color;
    background: $datepicker-day-background;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      color: $datepicker-day-hover-color;
      background: $datepicker-day-hover-background;
    }

    &.offset {
      color: $datepicker-day-offset-color;
      background: $datepicker-day-offset-background;
    }

    &.today {
      color: $datepicker-today-color;
      background: $datepicker-today-background;
    }

    &.selected {
      color: $datepicker-day-selected-color;
      background: $datepicker-day-selected-background;
    }
  }

  &-next,
  &-prev {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: $datepicker-day-hover-color;
    }
  }

  &-next {
    right: 5px;
  }

  &-prev {
    left: 5px;
  }
}

.rd-datepicker-open {
  display: block;
}