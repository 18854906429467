//
// Custom grid styles
// --------------------------------------------------

.row-spacer {
  flex-basis: 100%;
}

* + .row {
  margin-top: 50px;
}

@each $alias, $breakpoint in $grid-breakpoints {
  @include media-breakpoint-up( $alias ) {
    * + [class*='col-#{$alias}-'] {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .container-wide {
    max-width: 1800px;
  }
}

// Row gutter reset
.row-condensed{
  margin-left: 0;
  margin-right: 0;

  > [class*="col"]{
    padding-left: 0;
    padding-right: 0;
  }
}
