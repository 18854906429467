//
// RD Navbar Default Search
// --------------------------------------------------

%rd-navbar-default-search{
  .rd-navbar-search {
    display: inline-block;
    &.active .rd-navbar-search-form {
      opacity: 1;
      visibility: visible;
    }
  }

  .rd-navbar-search-form {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    bottom: 0;
    transition: .3s all ease;
    .form-group, input {
      height: 100%;
    }

    .form-label {
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      font-size: 26px;
      padding-left: 85px;
      left: 0;
      top: 50%;
      &.focus {
        left: 16px;
        font-size: 18px;
        top: 25px;
      }
    }

    .rd-navbar-search-form-input {
      height: 100%;
      font-size: 26px;
      padding: 0px 13% 0px 85px;
      border: none;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  .rd-navbar-search-toggle {
    margin-left: 25px;
    @include toggle-icons-via-rotation(24px, 24px, $white, '\f43b', '\f24c');
    position: relative;
    z-index: 3;
  }

  .rd-navbar-inner.active{
    .rd-navbar-nav-wrap, .rd-navbar-cart-wrap{
      opacity: 0;
      visibility: hidden;
    }
  }

  &.rd-navbar--is-stuck {
    .rd-navbar-search-form {
      .form-label {
        &.focus {
          top: 9px;
        }
      }
    }
  }

}

//
// Dark Theme
// --------------------------------------------------
%rd-navbar-default-search-dark{
  .rd-navbar-search-form {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    .form-label {
      color: $white;
      &.focus {
        color: $gray-700;
      }
    }
  }

  .rd-navbar-search-form-input {
    color: $white;
  }

}

//
// Light Theme
// --------------------------------------------------
%rd-navbar-default-search-light{
  .rd-navbar-search-form {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    .form-label {
      color: $gray-900;
      &.focus {
        color: $gray-700;
      }
    }
  }

  .rd-navbar-search-form-input {
    color: $gray-900;
  }


}
