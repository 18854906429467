//
// Custom Jumbotron
// --------------------------------------------------

.jumbotron {
  padding-left: 6%;
  padding-right: 6%;
  border-radius: $border-radius;
  p {
    font-weight: 400;
  }
}
