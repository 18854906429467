//
// RD Navbar Default Aside Right
// --------------------------------------------------

%rd-navbar-default-cart-aside-right{
  .rd-navbar-cart-dropdown{
    @include rd-navbar-sidebar(280px, "right");
    padding: 70px 15px 0;
    max-height: 100%;
    overflow-y: auto;
    &.active{
      transform: translateX(0);
    }
  }
}

//
// Dark Theme
// --------------------------------------------------
%rd-navbar-default-cart-aside-right-dark{
  .rd-navbar-cart-dropdown {
    color: $white;
    background: $black;
  }
}

//
// Light Theme
// --------------------------------------------------
%rd-navbar-default-cart-aside-right-light{
  .rd-navbar-cart-dropdown {
    color: $gray-900;
    background: $white;
  }
}
