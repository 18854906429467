// Element-groups

@mixin element-groups-custom($offset, $translate-offset) {
  -webkit-transform: translateY(-$translate-offset);
  transform: translateY(-$translate-offset);
  margin-bottom: -$translate-offset;
  margin-left: -$translate-offset;

  > *:not(.group-item) {
    display: inline-block;
    margin-top: $offset;
    margin-left: $offset;
  }

  > .group-item {
    display: inline-block;
    padding-top: $offset;
    padding-left: $offset;
  }
}