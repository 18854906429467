//
// Owl Carousel
// --------------------------------------------------

// Owl Carousel - Animate Plugin
// -------------------------

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Owl Carousel - Auto Height Plugin
// -------------------------

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}


// Core Owl Carousel CSS File
// -------------------------

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  text-align: center;
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}


// No Js
//
.no-js .owl-carousel {
  display: block;
}

// Owl Carousel - Lazy Load Plugin
// -------------------------

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

// Owl Carousel Video Plugin
// -------------------------

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}


// Owl Carousel Classic
// -------------------------

.owl-carousel-classic {
  overflow: hidden;

  // Owl Nav
  .owl-prev,
  .owl-next{
    position: absolute;
    top: 50%;
    transition: .3s;
    z-index: 1;

    &:before{
      position: absolute;
      top: 30px;
      font-size: 18px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      z-index: 1;
    }

    &:after{
      content: '';
      display: block;
      @include triangle(right, 43px, 86px, $white);
    }
  }

  .owl-prev{
    transform: translate(-100%, -50%);
    &, &:before{
      left: 0;
    }
  }

  .owl-next{
    transform: translate(100%, -50%);
    &, &:before{
      right: 0;
    }

    &:after{
      transform: rotate(180deg);
    }
  }

  &:hover{
    .owl-prev,
    .owl-next{
      transform: translate(0, -50%);
    }
  }

  // Owl Dots
  .owl-dots{
    margin-left: -8px;
    line-height: 8px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }

  .owl-dot{
    margin-left: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    border-radius: 50%;
    background: $gray-800;

    &.active{
      background: $white;
    }
  }
}

.owl-carousel-class-light{
  .owl-dot{
    background: $gray-500;
    &.active{
      background: $primary;
    }
  }
}


.owl-carousel-dots-custom-style{
  &.owl-carousel-default .owl-dot:before{
    color: $white;
    background-image: -webkit-linear-gradient(0deg, $white 0%, $white 100%);
  }
}

// Owl Carousel Default
// -------------------------

.owl-carousel-default{

  .owl-prev,
  .owl-next{
    position: absolute;
    top: 50%;
    transition: .3s;
    z-index: 1;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid $gray-700;
    color: $gray-700;
    opacity: .3;

    &:hover{
      opacity: 1;
    }
  }

  .owl-next{
    right: 20px;
  }

  .owl-prev{
    left: 20px;
  }


  // Owl Dots
  .owl-dots{
    margin-top: 30px;
    margin-left: -8px;
    line-height: 8px;
    text-align: center;
  }

  .owl-dot{
    margin-left: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    border-radius: 50%;
    background: $gray-800;

    &.active{
      background: $white;
    }
  }

  .tablet &{
    .owl-dots{
      display: block !important;
    }
    .owl-nav{
      display: none !important;
    }
  }

}

.owl-carousel-class-light{
  .owl-dot{
    background: $gray-500;
    &.active{
      background: $primary;
    }
  }
}

.owl-carousel-dots-custom-style{
  &.owl-carousel-default .owl-dot:before{
    color: $white;
    background-image: -webkit-linear-gradient(0deg, $white 0%, $white 100%);
  }
}

.owl-custom-pagination{
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
    width: 100%;
    li{
      padding: 0;
      cursor: pointer;
      box-shadow: $shadow-area-lg;
      transition: .3s all ease;
      transform: scale(0.8);

      &:hover, &.active{
        transform: scale(1);
      }
    }

    img{
      backface-visibility: hidden;
    }
  }

  & + .owl-carousel{
    .owl-next,
    .owl-prev{
      margin-top: -60px;
    }
  }

 // custom pagination variant 1
  &-variant-1{
    padding-left: 6px;
    .owl-dot-custom{
      width: 14px;
      height: 14px;
      background: $gray-500;
      border-radius: 50%;
      cursor: pointer;
      @include transition (0.35s all ease);
      &:hover, &.active {
        background: $picton-blue;
      }
      &.active {
        width: 18px;
        height: 18px;
        @include transform (translateY(2px));
      }
      & + .owl-dot-custom{
        margin-left: 18px;
      }
    }
  }
}



// Owl Carousel Customer Navigation
// ------------------------------

.owl-customer-navigation {
  display: inline-block;

  .owl-prev, .owl-next {
    width: 24px;
    height: 24px;
    line-height: 23px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s;
    border: 1px solid $gray-500;

    &:hover {
      background: $gray-900;
      color: $white;
      border-color: $gray-900;
    }
  }
}



// Owl Carousel Testimonials v2
// ------------------------------

.owl-carousel-testimonials-2{

  .owl-nav{
    display: inline-block;
    margin-top: 15px;
  }

  .owl-next{
    margin-left: 6px;
  }

  .owl-prev,
  .owl-next{
    display: inline-block;
    transition: .3s;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border: 1px solid $gray-500;
    color: $gray-800;
    border-radius: $border-radius-small;

    &:hover{
      background: $gray-900;
      color: $white;
      border-color: $gray-900;
    }
  }
}

.owl-carousel-testimonials-3{
.owl-dots{
  margin-top: 20px;
  position: static;
  transform: none;
}

}

.owl-carousel-simple-quote{
  .owl-dots{
    position: static;
    text-align: right;
    transform: none;
  }
}



@include rules-responsive(veil, $grid-breakpoints, owl-dots){
  .owl-dots{
    display: none!important;
  }
}

@include rules-responsive(reveal, $grid-breakpoints, owl-dots){
  .owl-dots{
    display: block!important;
  }
}

@include rules-responsive(veil, $grid-breakpoints, owl-nav){
  .owl-nav{
    display: none!important;
  }
}

@include rules-responsive(reveal, $grid-breakpoints, owl-nav){
  .owl-nav{
    display: block!important;
  }
}