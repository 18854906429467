//
// Page Loaders
// --------------------------------------------------

// Base styles
// -------------------------

.page-loader-variant-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: $page-loader-v-1-bg;
  transition: .3s all ease;
  z-index: 9999999;
  padding: 0 25px;

  [data-x-mode] & { display: none; }

  .spinner{
    height: 56px;
    width: 56px;
    background: url("../images/633328.png") no-repeat transparent;
    background-position: -2240px 0;
    animation: 1.5s sprite-animation steps(40) infinite;
    display: inline-block;

  }

  &.show-loader {

  }

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &.ending {

  }
}
