//
// Easy Responsive Tabs
// --------------------------------------------------


// Classic Tabs
// --------------------------------------------------

.responsive-tabs {

  // Collapse Tab
  .resp-accordion {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-700;
    position: relative;
    transition: color 0.2s ease-in;
    padding: 15px 20px 15px 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &:hover {
      cursor: pointer;
      color: $white;
    }

    .resp-arrow {
      position: absolute;
      top: 11px;
      bottom: 0;
      right: 0px;
      width: 20px;
      font-family: "Material Design Icons";
      font-size: 21px;
      font-weight: 400;
      transition: .2s;

      &:before {
        content: '\f504';
      }
    }

    // Active tab accordion
    &.resp-tab-active {
      color: $white;

      .resp-arrow:before {
        content: '\f465';
      }
    }
  }

  // Accordion Content
  .resp-tab-content {
    padding: 2px 5px 17px 2px;
  }

  // Tabs List
  .resp-tabs-list {
    display: none;
    padding: 0;
    margin-bottom: 0;

    li {
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      list-style-type: none;
      color: $gray-700;
      position: relative;
      transition: color 0.2s ease-in;
      padding: 0 0 13px;

      &.resp-tab-active,
      &:hover {
        color: $gray-800;
      }
    }
  }

  // Tab Content
  .resp-tab-content {
    display: none;
  }

  // Turn accordion to Horizontal Tabs
  @include media-breakpoint-up(lg) {
    &[data-type="horizontal"],
    &[data-type="vertical"] {
      border-bottom: none;

      .resp-tab-content + .resp-accordion {
        border-top: none;
      }

      .resp-accordion {
        display: none;
      }

      .resp-tabs-list {
        display: block;
      }
    }
  }

  // Style for vertical tabs
  @include media-breakpoint-up(xl) {
    &[data-type="vertical"] {
      display: flex;
      flex: 0 1 auto;
      align-items: flex-start;

      .resp-tabs-list {
        text-align: left;
        flex-basis: 25%;

        li {
          display: block;
        }

        li + li {
          margin-left: 0;
        }
      }

      .resp-tabs-container {
        flex-basis: 75%;
      }
    }
  }
}


// Classic Tabs
// --------------------------------------------------

.responsive-tabs-classic {
  border-bottom: 1px solid $gray-500;

  .resp-tab-content + .resp-accordion {
    border-top: 1px solid $gray-500;
  }

  @include media-breakpoint-up(lg) {
    &[data-type="vertical"],
    &[data-type="horizontal"] {
      border-bottom: none;

      .resp-tab-content + .resp-accordion {
        border-top: none;
      }

      .resp-tabs-list {
        li {
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 1px;
            background-color: $gray-800;
            transition: .4s;
          }

          &.resp-tab-active {
            &:before {
              width: 100%;
            }
          }
        }

        li + li {
          margin-left: 10px;
        }
      }

      // Tab Content
      .resp-tab-content {
        padding-top: 32px;
      }
    }
  }

  // Turn Horizontal Tabs to Vertical Tabs
  @include media-breakpoint-up(xl) {
    // Style for horizontal tabs
    &[data-type="horizontal"] {
      .resp-tabs-list {
        li + li {
          margin-left: 33px;
        }
      }
    }

    // Style for vertical tabs
    &[data-type="vertical"] {
      .resp-tabs-list {
        border-left: 1px solid $gray-500;

        li {
          padding: 0 0 0 30px;

          &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 0;
          }

          &.resp-tab-active {
            &:before {
              height: 100%;
              width: 1px;
            }
          }
        }

        li + li {
          margin-left: 0;
          margin-top: 25px;
        }

      }
      .resp-tab-content {
        padding-top: 0;
      }
    }
  }
}


// Boxed Tabs
// --------------------------------------------------

.responsive-tabs-boxed {
  // Collapse Tab
  .resp-accordion {
    border-radius: $border-radius-small;
    border: 1px solid $gray-500;
    padding-left: 20px;
    padding-right: 40px;


    .resp-arrow {
      right: 20px;
    }
  }

  // Tab Content
  .resp-tab-content {
    border-bottom: 1px solid $gray-500;
    border-right: 1px solid $gray-500;
    border-left: 1px solid $gray-500;
    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
    padding-left: 20px;
    padding-right: 20px;
  }

  .resp-tab-content + .resp-accordion {
    margin-top: 5px;
  }

  // Active tab
  .resp-tab-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid transparent;
  }

  // Turn accordion to Horizontal Tabs
  @include media-breakpoint-up(lg) {
    &[data-type="horizontal"],
    &[data-type="vertical"] {
      .resp-tabs-list {
        font-size: 0;
        border-bottom: 1px solid $gray-500;

        li {
          position: relative;
          margin-bottom: -1px;
          text-transform: uppercase;
          padding: 13px 20px;
          color: $gray-800;
          font-size: 14px;
          font-weight: 900;
          letter-spacing: .06em;
          background: $gray-300;
          border-left: 1px solid $gray-500;
          border-top: 1px solid $gray-500;
          border-bottom: 1px solid transparent;

          &:last-child {
            border-right: 1px solid $gray-500;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            border-bottom: 1px solid $gray-500;
          }

          &.resp-tab-active {
            border-bottom-color: $white;
          }

          &.resp-tab-active {
            background: $white;
            &:after {
              width: 0;
            }
          }
        }
      }

      .resp-tab-content + .resp-accordion {
        margin-top: 0px;
      }

      .resp-tab-content {
        border-bottom: none;
        border-right: none;
      }

      .resp-tab-content {
        padding: 44px 61px 40px 61px;
      }
    }

    // Turn Horizontal Tabs to Vertical Tabs
    @include media-breakpoint-up(xl) {
      &[data-type="vertical"] {
        border-top: 1px solid $gray-500;

        .resp-tabs-list {
          margin: 0 0 0 -1px;
          border-bottom: none;
          border-top: none;

          li {
            border-top: 1px solid $gray-500;
            border-right: none;

            &:first-child {
              border-top: none;
            }

            &:last-child {
              border-bottom: 1px solid $gray-500;
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: auto;
              left: auto;
              right: -1px;
              width: 1px;
              border-right: 1px solid $white;
            }

            &.resp-tab-active {
              &:after {
                height: 102%;
              }
            }
          }
        }

        .resp-tab-content {
          padding: 43px 43px 43px 60px;
        }
      }
    }
  }
}