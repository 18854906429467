//
// RD Navbar
// --------------------------------------------------

$rd-navbar-fixed-height: 56px;

%rd-navbar-transition {
  transition: .3s all cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

%rd-navbar-default-link-font{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  font-weight: 400;
  text-transform: uppercase;
}

.rd-navbar {
  display: none;
  position: relative;
  z-index: 99999;
  text-align: left;
  ul {
    list-style-type: none;
    margin-bottom: 0;
  }
  a{
    transition: .3s all ease;
  }

  .responsive-tabs-classic {
    border-bottom: none;
    .resp-tab-content + .resp-accordion{
      border-top: none;
    }
  }
}

// Stuck menu
.rd-navbar-static.rd-navbar--is-stuck {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}


.rd-navbar-fixed,
.rd-navbar-static{
  display: block;
}

.rd-navbar--no-transition {
  &, *{
    transition: none!important;
  }
}

[data-x-mode] {
  .rd-navbar-fullwidth,
  .rd-navbar-static {

    // Main nav
    .rd-navbar-nav {
      > .rd-navbar--has-dropdown,
      > .rd-navbar--has-megamenu {
        > .rd-navbar-submenu-toggle {
          display: inline-block;
          margin-left: 5px;
          font-size: 10px;
          line-height: 1;
          vertical-align: middle;
          transition: .25s;

          &:before {
            content: '\f078';
            font-family: $icon-font-fontawesome;
          }

          &:hover {
            cursor: pointer;
            color: $primary;
          }
        }

        &.opened {
          > .rd-navbar-submenu-toggle {
            transform: rotateX(180deg);
          }
        }
      }
    }

    // Dropdown
    .rd-navbar-dropdown {
      .rd-navbar--has-dropdown {
        > .rd-navbar-submenu-toggle {
          position: absolute;
          top: 50%;
          bottom: 0;
          right: 13px;
          transform: translateY(-50%);
          z-index: 1;
          height: 34px;
          font-size: 22px;
          line-height: 34px;
          transition: .3s all ease;
          color: inherit;

          &:before {
            content: "\f238";
            font-family: "Material Design Icons";
          }

          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          > .rd-navbar-submenu-toggle {
            color: white;
          }
        }
      }
    }

    &.rd-navbar-transparent {
      .rd-navbar-nav {
        > .rd-navbar--has-dropdown,
        > .rd-navbar--has-megamenu {
          > .rd-navbar-submenu-toggle {
            color: white;
            &:hover { color: $primary; }
          }
        }
      }
    }
  }
}
