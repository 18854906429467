// Divider-gradient

@mixin divider-gradient($color-1, $color-2) {
  background: rgba($color-1,1);
  background: -moz-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba($color-1,1)), color-stop(50%, rgba($color-2,1)), color-stop(100%, rgba($color-1,1)));
  background: -webkit-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
  background: -o-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
  background: -ms-linear-gradient(left, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
  background: linear-gradient(to right, rgba($color-1,1) 0%, rgba($color-2,1) 50%, rgba($color-1,1) 100%);
}