// Alerts
@mixin alert-custom-variant($background, $border, $body-color, $heading-color) {
  padding: 22px;
  border-radius: 0;
  background-color: $background;
  border-left: 5px solid $border;
  text-align: left;
  color: $body-color;

  hr {
    border-top-color: darken($border, 5%);
  }

  h4 {
    margin-bottom: 18px;
    color: $heading-color;
  }

  .alert-link {
    color: darken($body-color, 10%);
  }
}