//
// Box icons
// --------------------------------------------------

// Base styles
// -------------------------

.box-icon {
  position: relative;
  padding: 42px 25px 27px 25px;
}

// Box sizes
// -------------------------

.box-icon-sm {
  padding: 15px;
}
.box-icon-lg{
  padding: 50px;
}

// Box styles
// --------------------------

.box-icon-bordered{
  border: 2px solid $gray-300;
}

// Icon styles
//---------------------------

.box-icon {
  .icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
