//
// Pricing&Plans
// --------------------------------------------------

// Box Pricing
// --------------------------------------------------

.box-pricing{
  text-align: left;

  &-title{
    display: flex;
    flex: 0 1 100%;
    flex-direction: row;
    flex-wrap: nowrap;

    > *, &:after{
      flex: 0 1 auto;
      flex-grow: 0;
    }

    .box-pricing-price{
      margin-top: 0;
    }

    .box-pricing-name{
      padding-top: 5px;
    }

    .box-pricing-dots{
      position: relative;
      flex-grow: 1;
      top: -6px;
      left: -2px;
      //content: '';
      border-bottom: 2px dotted $gray-800;
    }
  }

  // List offsets

  li + li{
    margin-top: 23px;
  }
}

// Box Planning
// --------------------------------------------------

// Base styles
// -------------------------

.box-planning {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 11%;

  &-label {
    display: none;
    z-index: 2;
    color: $white;
    span {
      position: absolute;
    }
  }

  // Reset default styles

  h1, .h1 {
    margin-bottom: 0;
    margin-top: 0;
  }

  h2, .h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .small {
    margin-top: 0;
    font-size: 13px;
  }

  .btn {
    margin-top: 35px;
  }

  .divider{
    width: 100%;
    margin-top: 18px;
    margin-bottom: 21px;
  }

  .plan-price{
    sup{
      font-size: 35%;
      top: -1.2em;
      &.big{
        font-size: 60%;
        top: -0.6em;
      }
    }
    sub{
      font-size: 35%;
      bottom: 0;
    }
  }

  .list, .list-separated {
    span + span {
      margin-left: 6px;
    }
  }

  .box-planning-body{
    padding: 11% 8% 8% 8%;
    border: 1px solid $gray-200;
    border-top: none;
  }

  &.active {
    .box-planning-label {
      display: block;
    }
  }
}


// Box planning type 1 styles
// -------------------------

.box-planning-type-1 {

  max-width: 300px;
  overflow: hidden;

  .box-planning-label{
    position: absolute;
    top: -35px;
    right: -35px;
    font-size: 11px;
    line-height: 34px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.119em;

    > span {
      right: 32px;
      top: 48px;
      transform: rotate(47deg);
    }

    .box-planning-svg{
      width: 150px;
      height: 150px;
      fill: $primary;
    }
  }

  &.active{
    box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.15);
    .h1 {
      color: $primary;
      background: -webkit-linear-gradient(0deg, $primary 0%, $dollar-bill 100%); /* safari5.1+,chrome10+ */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

// Box planning type 2 styles
// -------------------------

.box-planning-type-2 {
  max-width: 320px;
  .box-planning-label{
    position: absolute;
    top: 25px;
    right: -17px;

    span {
      right: 10px;
      top: 10px;
      transform: rotate(-27deg);
    }

    .box-planning-svg{
      width: 75px;
      height: 75px;
    }
  }

  &.active{
    box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.15);
  }
}

// Box planning type 1, 2 styles
// -------------------------

.box-planning-type-1,
.box-planning-type-2 {
  border: 1px solid $gray-200;
}

// Box planning type 3,4 styles
// -------------------------

.box-planning-type-3, .box-planning-type-4{
  max-width: 295px;
  padding: 0;

  .box-planning-header{
    padding: 11%;

    h2, .h2{
      margin-bottom: 0;
    }
    h3, .h3{
      margin-top: 5px;
      margin-bottom: 7px;
    }
  }

  .btn {
    margin-top: 25px;
  }

  .btn-default{
    @include button-variant-custom($white, $gray-800, $gray-800, $white, $primary, $primary);
  }

}

// Box planning type 4 styles
// -------------------------

.box-planning-type-4{
  max-width: 270px;

}


// Media styles
// -------------------------
@include media-breakpoint-up(lg) {

  // Box planning type 1, 2 styles
  .box-planning-type-1,
  .box-planning-type-2{
    border: none;
  }

}
