//
// Custom Progress Bars
// --------------------------------------------------

// Linear Progress Bars
// --------------------------------------------------
.progress-linear {
  position: relative;
  height: 12px;
  .progress-bar-linear-wrap, .progress-bar-linear {
    height: 100%;
    border-radius: $border-radius;
  }
  .progress-bar-linear-wrap{
    background: $gray-200;
  }
  .progress-bar-linear {
    width: 0;
    @include transition(.5s all ease);
  }
  .progress-value{
    &:after{
      content:"%";
    }
  }
}

// Circle Progress Bars
// --------------------------------------------------
.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;

  &:before {
    content: '';
    position: absolute;
    width: 74%;
    height: 74%;
    border-radius: 50%;
    border: 1px solid $gray-300;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  canvas {
    vertical-align: middle;
  }

  span {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 900;
    font-size: 38px;
    line-height: 38px;

    &:after {
      content: '%';
    }
  }
}

// Change bootstrap progress
// --------------------------------------------------

.progress{
  height: $progess-height;
}

.progress-bar {
  font-weight: $progress-font-weight;
  font-size: $progress-font-size;
  line-height: $progess-height;

  box-shadow: none;
}
