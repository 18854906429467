/*
 * @subsection   RD-Range
 */

$range-primary-color: $gray-800;

.rd-range-container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  width: 100%;
}

.rd-range-input-container {
  position: relative;

  label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $range-primary-color;
    font-size: 12px;
  }

  .rd-range-input-value {
    border-bottom: 2px solid $range-primary-color;
    color: #A5A5A5;
    max-width: 100px;
    height: 40px;
    padding-top: 17px;
    font-size: 14px;
    text-align: right;
    padding-right: 7px;
  }
}

.rd-range {
  height: 18px;
  margin-top: 25px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  &__wrap {
    position: relative;
    width: 100%;
    background-color: $gray-500;
    height: 2px;
  }


  &__pointer {
    position: absolute;
    cursor: pointer;
    top: 1px;
    left: 6%;
    z-index: 1;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0% 15%;
    transition: transform 120ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    overflow: visible;
    background-color: $white;
    border: 1px solid $range-primary-color;
    background-clip: padding-box;
    transition: 0.02s cubic-bezier(.35, 0, .25, 1);

    @include media-breakpoint-up(md) {
      width: 12px;
      height: 12px;
    }
  }

  &__line {
    background-color: $range-primary-color;
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    transition: 0.02s cubic-bezier(.35, 0, .25, 1);
  }

  &.hasTooltip {
    .rd-range__pointer-tooltip {
      font-size: 13px;
      color: $gray-800;
      transition: color .3s cubic-bezier(.35, 0, .25, 1);
      position: absolute;
      bottom: -20px;
      left: 50%;
      @include transform ( translateX(-50%) );
      pointer-events: none;
    }

    .rd-range__pointer {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      &:hover,
      &:active{
        background-color: $range-primary-color;
      }
    }
  }
}