//
// Box Comment
// --------------------------------------------------

// Base styles
// -------------------------
.box-comment {
  &-meta {
    margin-bottom: 0;
    font-size: 12px;

    a:hover {
      color: $picton-blue;
    }

    &-icon {
      font-size: 14px;
    }
  }

  &-title {
    margin-top: 0;
    font-size: $font-size-sm;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &-body {
    font-size: $font-size-sm;
  }

  @include media-breakpoint-up(sm) {
    &-img {
      width: 60px;
      height: 60px;
    }
  }
}

// Classic Comments
// -------------------------
.box-comment-classic {
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    left: 19px;
    background: $gray-500;
    width: 1px;
  }

  &:before {
    top: 48px;
    bottom: -8px;
  }

  &:last-child:before {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    > .box-comment {
      .box-comment-img {
        width: 30px;
        height: 30px;
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    &:after {
      display: none;
    }

    & + .box-comment-classic:after,
    .media + .box-comment-classic:after {
      display: block;
    }

    .media + .box-comment-classic:after {
      bottom: auto;
      top: 19px;
      right: 100%;
      left: auto;
      height: 1px;
      margin-right: 8px;
      width: 22px;
    }

    .box-comment-header {
      margin-top: 5px;
    }

    > .box-comment {
      margin-left: 80px;
    }

    &:before {
      top: 68px;
    }

    &:before, &:after {
      left: 29px;
    }

    .media + .box-comment-classic:after {
      top: 29px;
      width: 42px;
    }
  }
}

.box-comment-classic + .box-comment-classic,
.box-comment-classic > .box-comment {
  margin-top: 16px;
}

// Boxed Comments
// -------------------------
.box-comment-boxed {
  .box-comment-img{
    margin-top: 15px;
    border: 1px solid $gray-500;
    background: $white;
    padding: 2px;
    margin-right: -30px;
  }

  .box-comment-body{
    margin-top: 10px;
  }

  .media-body{
    padding: 15px 20px 15px 30px;
    border-radius: $border-radius-small;
    border: 1px solid $gray-500;
  }

  @include media-breakpoint-up(xl) {
    .box-comment-img{
      margin-right: -50px;
      padding: 4px;
    }

    > .box-comment-boxed{
      margin-left: 30px;
    }

    .box-comment-meta{
      float: right;
    }

    .media-body{
      padding: 20px 30px 20px 50px;
    }

    > .box-comment-outboxed{
      margin-left: 80px;
    }
  }
}

.box-comment-boxed + .box-comment-boxed,
.box-comment-boxed > .box-comment {
  margin-top: 20px;
}


// Outbox Comments
// -------------------------
.box-comment-outboxed {
  .box-comment-body{
    margin-top: 5px;
  }

  @include media-breakpoint-up(xl) {
    .box-comment-body{
      margin-top: 10px;
    }

    .box-comment-img{
      margin-top: 10px;
    }

    .media-body{
      padding: 20px;
      border-radius: $border-radius-small;
      border: 1px solid $gray-500;
    }

    .box-comment-meta {
      > li:first-child {
        float: right;
        margin-left: 20px;
      }
    }

    > .box-comment-outboxed{
      margin-left: 80px;
    }
  }
}

.box-comment-outboxed + .box-comment-outboxed,
.box-comment-outboxed > .box-comment {
  margin-top: 20px;
}

