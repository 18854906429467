//
// Box member
// --------------------------------------------------


// Base Styles
// --------------------------------------------------

.box-member {
  position: relative;
  display: inline-block;

  img {
    display: inline-block;
  }
}

// hover from 768
@include media-breakpoint-up(md) {
  .box-member .box-member-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 81%;
    z-index: 2;
    transition: .3s;

    // Box Member Caption
    .box-member-caption {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      margin-top: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-transform: uppercase;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      .list-inline {
        li {
          transform: translateY(40px);
          transition: .35s all ease;
          opacity: 0;

          &:nth-child(2) {
            transition-delay: .1s;
          }

          &:last-child {
            transition-delay: .2s;
          }
        }
      }
    }

    &:hover {
      background: rgba($white, .7);

      .list-inline li {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

// Member block type 3
// --------------------------------------------------
.member-block-type-3{
  margin-left: auto;
  margin-right: auto;
  max-width: 246px;
  box-shadow: $shadow-area-md;
  border-radius: $border-radius;

  .member-block-body{
    padding: 40px 30px 0 30px;
  }

  .member-block-footer{
    .btn{
      font-size: 12px;
      border-color: transparent;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .btn-group{
    display: block;
    margin-left: -4px;
    margin-right: -4px;
    .btn{
      @include button-variant-custom($gray-800, $white, $gray-500, $white, $gray-800, $gray-800);
      float:  none;
      width: 33.5%;
      .icon{
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}

// Member block type 5
// --------------------------------------------------
.member-block-type-5{
  .member-block-body {
    padding: 34px 15px;
    .contact-info {
      li {
        .icon {
          padding-right: 10px;
        }
        & + li {
          margin-top: 28px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      padding: 34px 55px;
    }
  }
}