//
// RD Navbar Default Aside Left
// --------------------------------------------------

%rd-navbar-default-menu-first-lvl{
  .rd-navbar-nav {
    @include clearfix;
    > li {
      float: left;
      position: relative;
      > a {
        @extend %rd-navbar-default-link-font;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        letter-spacing: 0.06em;
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          right: 50%;
          height: 2px;
          margin-top: 4px;
          @include bg-variant-custom(linear-gradient(90deg, $primary 0%, $dollar-bill 100%));
          transition: .3s all ease;
        }
        > .rd-navbar-label {
          position: absolute;
          margin: -10px 0 0 -15px;
        }
      }

      &.active > a, &.focus > a, > a:hover {
        &:after {
          left: 0;
          right: 0;
        }
      }

      &.rd-navbar--has-megamenu {
        position: static;
      }

      + li {
        margin-left: 37px;
      }
    }

    li.focus {
      > .rd-navbar-dropdown, > .rd-navbar-megamenu {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

//
// Dark Theme
// --------------------------------------------------
%rd-navbar-default-menu-first-lvl-dark{

  .rd-navbar-nav {
    > li {
      a {
        color: $white;
      }
    }
  }

}

//
// Light Theme
// --------------------------------------------------
%rd-navbar-default-menu-first-lvl-light{
  .rd-navbar-nav {
    > li {
      a {
        color: $gray-900;
      }
    }
  }

}
