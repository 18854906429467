//
// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 20px, 30px, 50px, 80px, 100px, 115px);

html .page{
  @include indent-responsive(inset, left, padding-left, $grid-breakpoints, $insets);
  @include indent-responsive(inset, right, padding-right, $grid-breakpoints, $insets);
}


// Elements
// -------------------------
.inset-left-7p{
  padding-left: 7%;
}
.inset-right-7p{
  padding-right: 7%;
}

.inset-left-11p{
  padding-left: 11%;
}

.inset-right-11p{
  padding-right: 11%;
}


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 4px, 10px, 14px, 20px, 24px, 30px, 34px, 41px, 50px, 66px, 98px, 110px, 124px);

html .page{
  @include indent-responsive(offset, top, margin-top, $grid-breakpoints, $offsets);
}
