//
// Labels
// --------------------------------------------------

.label{
  padding: .2em .6em .2em;
}

// Labels styles
// -------------------------

.label-custom {
  display: inline-block;
  padding: 8px;
  font-size: 8px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.06em;
  transition: .3s;
  h1 &, h2 &, h3 &, h4 &, h5 &, h6 &, p &{
    margin-left: 10px;
  }

  &,&:hover,&:focus{
    color: $white;
  }
}

.label-ellipsis-custom{
  border-radius: 10px;
}

.label-rounded-custom{
  border-radius: 3px;
}

// Labels sizes
// -------------------------

.label-xs-custom{
  padding: 3px 5px;
}
.label-sm-custom{
  padding: 7px;
}
.label-lg-custom{
  font-size: 12px;
  padding: 9px 10px;
}
.label-xl-custom{
  font-size: 12px;
  padding: 10px 12px;
}


// Labels colors
// -------------------------

.label-deluge{
  @include badge-variant($label-deluge-bg);;
}

.label-default {
  @include badge-variant($label-default-bg);;
}

.label-primary {
  @include badge-variant($label-primary-bg);;
}

.label-success {
  @include badge-variant($label-success-bg);;
}

.label-info {
  @include badge-variant($label-info-bg);;
}

.label-warning {
  @include badge-variant($label-warning-bg);;
}

.label-danger {
  @include badge-variant($label-danger-bg);;
}

