//
// Navs Custom
// --------------------------------------------------

.nav-tabs {
  > li, > li:last-child{
    margin-bottom: -1px;
  }
}

.nav-stacked {
  > li + li {
    margin-top: 4px;
  }
}

// Stacked pills
// --------------------------------------------------


@include media-breakpoint-up(lg) {
  .nav-stacked-container {
    .tab-content,
    .nav-stacked {
      display: table-cell;
      vertical-align: top;
    }

    .nav-stacked {
      width: 25%;
    }

    .tab-content {
      width: 73%;
      padding-left: 2%;
    }
  }
}
