// Pull Utilities

@mixin make-pull-alignment( $alias, $resolution ) {
  $alias: str_insert($alias, '-', str_length($alias) + 1);

  .pull-#{$alias}left {
    float: left;
  }

  img.pull-#{$alias}left {
    padding-right: 28px;
  }

  .pull-#{$alias}base {
    float: none;
  }

  .pull-#{$alias}right {
    float: right;
  }

  img.pull-#{$alias}right {
    padding-left: 28px;

  }
}
