//
// Counters
// --------------------------------------------------

// Base Styles

.counter{

}

// Styles

.counter-type-1{

}
.counter-type-2{

}
.counter-type-3{

}
