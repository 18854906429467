/**
 * RD Timepicker
 * @Section
 */

$primary: $primary;

$picker-bg: white;
$picker-shadow: 3px 5px 30px 0px rgba(0, 0, 0, 0.3);
$picker-panel-bg: $primary;
$picker-circle-bg: #eeeeee;
$picker-panel-time-fz: 56px;

.rd-timepicker {
  width: 100%;
  z-index: 10;
  transform: translate(-50%, 30px);
  left: 47%;
  top: 90px;
  position: absolute;
  margin-right: auto;
  background: $picker-bg;
  box-shadow: $picker-shadow;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s all ease-out;
  pointer-events: none;

  &.rd-timepicker--active {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0px);
    pointer-events: all;
    padding-bottom: 20px;
  }

  &:before {
    top: -12px;
    right: 20px;
    content: '';
    position: absolute;
    border: 6px solid transparent;
    border-bottom: 6px solid $primary;
  }
  &-panel {
    background: $picker-panel-bg;
    text-align: center;
    position: relative;
    height: 100px;
    padding: 25px 50px;
  }
  &-time {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 42px;
    line-height: 47px;

    color: #fff;

    &__hours, &__minutes {
      cursor: pointer;
      font-weight: 700;
      color: lighten(desaturate(adjust_hue($primary, -2.9290617848969873), 7.783194249359667%), 22.745098039215677%);
    }
  }
  .rd-timepicker-time--active {
    color: $white;
  }
  &-meridiem {
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &__item {
      display: block;
      line-height: 1.4;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
      color: lighten(desaturate(adjust_hue($primary, -2.9290617848969873), 7.783194249359667%), 22.745098039215677%);
    }
  }

  &-wrapper {
    width: 260px;
    height: 260px;
    margin: 20px auto;
    border-radius: 50%;
    background: $picker-circle-bg;
    position: relative;
  }

  &-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: $primary;
    border-radius: 50%;
  }
  &-line {
    position: absolute;
    background-color: $primary;
    width: 2px;
    height: 108px;
    top: 128.5px;
    left: 129.5px;
    transform-origin: 1px 1px;
    transform: rotate(180deg);
  }

  &-display {
    color: #3d3a3b;
    opacity: 0;
    transform: scale(1.2);
    visibility: hidden;
    transition: .4s ease;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &-inner {
      li {
        position: absolute;
        text-align: center;
        border-radius: 50%;
        top: 111.5px;
        left: 111.5px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        overflow: hidden;
        &.rd-timepicker-display__item--active {
          background: $primary;
          color: #fff;
        }
      }
      @for $i from 1 through 12 {
        @if $i > 3 {
          > *:nth-of-type( #{$i} ) {
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(102.5px) rotate(-#{(($i - 1) * 30) - 60}deg);
          }
        } @else if $i == 1 {
          > *:nth-of-type( #{$i} ) {
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(102.5px) rotate(#{(($i - 1) * 30) + 60}deg);
          }
        } @else if $i == 2 {
          > *:nth-of-type( #{$i} ) {
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(102.5px) rotate(#{(($i - 1) * 30)}deg);
          }
        } @else if $i == 3 {
          > *:nth-of-type( #{$i} ) {
            transform: rotate(#{(($i - 1) * 30) - 60}deg) translate(102.5px) rotate(#{(($i - 1) * 30) - 60}deg);
          }
        }
      }
    }

    &--active {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 320px;
    left: 57%;

    &-wrapper {
      width: 280px;
      height: 280px;
    }

    &-time {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: $picker-panel-time-fz;
      line-height: 51px;
    }

    &-display {
      &-inner {
        li {
          position: absolute;
          text-align: center;
          border-radius: 50%;
          top: 119.5px;
          left: 119.5px;
        }
      }
    }

    &-line {
      top: 136.5px;
      left: 138.5px;
    }
  }
}