//
// Dropdowns Custom
// --------------------------------------------------

// Dropdown arrow/caret
.caret{
  margin-left: 5px;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  display: inline-block;
}

// The dropdown menu (ul)
.dropdown-menu{
  border: none;
  font-size: 13px;
  border-radius: $border-radius-small;

  // Links within the dropdown menu
  > li > a {
    font-weight: 400;
    padding: 5px 20px;
  }

  .divider{
    width: 100%;
  }
}

// Dropdown section headers
.dropdown-header {
  font-size: 12px;
  padding: 8px 20px 3px;
}
