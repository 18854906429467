//
//  Dropcaps
// --------------------------------------------------

.dropcap{
  &:first-letter{
    padding-right: 8px;
    margin-left: -10px;
    margin-top: 2px;

    float: left;

    font-size: 120px;
    line-height: 0.7;
    font-weight: 300;
  }

  &-dark{
    &:first-letter {
      color: $gray-700;
    }
  }
}